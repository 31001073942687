import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <span
      className='link footer__link'
    >
      2022 © All rights reserved. 
    </span>
  </footer>
)

export default Footer
